:root {
  --primary: #0037ff;
  --secondary: #264653;
  --accent: #E9C46A;
  --background: #0A192F;
  --text: #F8F9FA;
}

.coding-registration {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: var(--background);
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-header {
  padding: 2rem;
  background: linear-gradient(135deg, var(--background) 0%, #0F172A 100%);
  border-radius: 1rem;
  margin-bottom: 2rem;
}

.session-highlights {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 1rem;
  padding: 2rem;
  margin-top: 1.5rem;
}

.highlight-card {
  display: grid;
  gap: 2rem;
}

.highlight-main {
  display: flex;
  gap: 2rem;
  align-items: center;
  padding-bottom: 2rem;
  border-bottom: 2px solid var(--primary);
}

.main-icon {
  font-size: 3rem;
  color: var(--accent);
  flex-shrink: 0;
}

.program-details {
  color: var(--accent);
  font-size: 1.1rem;
  margin: 1rem 0;
}

.features-list {
  list-style: none;
  padding: 0;
  display: grid;
  gap: 1rem;
}

.features-list li {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: var(--text);
}

.cta-box {
  background: rgba(234, 196, 106, 0.1);
  border-left: 4px solid var(--accent);
  padding: 1.5rem;
  border-radius: 8px;
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.cta-icon {
  color: var(--accent);
  font-size: 1.5rem;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .highlight-main {
    flex-direction: column;
    text-align: center;
  }
  
  .detail-grid {
    grid-template-columns: 1fr;
  }
  
  .cta-box {
    flex-direction: column;
    text-align: center;
  }
}

.detail-card {
  background: #0F172A;
  border-radius: 0.8rem;
  padding: 1.5rem;
  margin: 1.5rem 0;
}

.detail-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.detail-block {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  background: #1E293B;
  border-radius: 0.5rem;
  align-items: center;
}

.detail-block .icon {
  font-size: 1.5rem;
  color: var(--accent);
  min-width: 40px;
}

.detail-label {
  color: #94A3B8;
  font-size: 0.9rem;
  margin-bottom: 0.2rem;
}

.detail-value {
  color: var(--text);
  font-weight: 600;
  margin-bottom: 0;
}

.detail-sub {
  color: #94A3B8;
  font-size: 0.85rem;
  margin-top: 0.2rem;
}

.cta-text {
  color: var(--text);
  font-size: 1.1rem;
  line-height: 1.6;
  margin-top: 1.5rem;
  padding: 1rem;
  border-top: 2px solid var(--primary);
}

@media (max-width: 768px) {
  .form-header h1 {
    font-size: 1.8rem;
    flex-direction: column;
  }
  
  .detail-grid {
    grid-template-columns: 1fr;
  }
}

.highlight {
  color: var(--accent);
  font-weight: 600;
}

.header-highlights {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 1.5rem;
  flex-wrap: wrap;
}

.highlight-item {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  background: rgba(234, 196, 106, 0.1);
  padding: 0.8rem 1.5rem;
  border-radius: 50px;
}

.emoji {
  font-size: 1.4rem;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }
  
  .subheading {
    font-size: 1.1rem;
  }
  
  .highlight-item {
    padding: 0.6rem 1rem;
  }
}

.form-section {
  border: 1px solid #1E293B;
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 2rem;
  background: #0F172A;
}

.form-section legend {
  color: var(--accent);
  padding: 0 1rem;
  font-weight: 600;
  font-size: 1.1rem;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input-group label {
  color: var(--text);
  font-weight: 500;
}

/* Add these styles */
.other-input {
  width: 100%;
  padding: 0.8rem 1.2rem;
  border: 2px solid #334155;
  border-radius: 0.5rem;
  background: #1E293B;
  color: var(--text);
  font-size: 1rem;
  transition: all 0.3s ease;
  margin-top: 0.5rem;
}

.other-input:focus {
  border-color: var(--primary);
  box-shadow: 0 0 0 3px rgba(42, 157, 143, 0.2);
  outline: none;
}

/* Update existing checkbox grid */
.checkbox-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
}

/* DELETE THESE DUPLICATE RULES */
.input-group input {
  padding: 0.8rem;
  border: 1px solid #334155;
  border-radius: 0.5rem;
  background: #1E293B;
  color: var(--text);
  transition: all 0.3s ease;
}

.input-group input:focus {
  border-color: var(--primary);
  box-shadow: 0 0 0 3px rgba(42, 157, 143, 0.2);
}

/* Radio and Checkbox Styling */
.radio-group,
.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.radio-option,
.checkbox-option {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: background 0.3s ease;
}

.radio-option:hover,
.checkbox-option:hover {
  background: #1E293B;
}

.custom-radio,
.custom-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.2rem;
  height: 1.2rem;
  border: 2px solid var(--primary);
  border-radius: 50%;
  transition: all 0.3s ease;
}

.custom-checkbox {
  border-radius: 0.25rem;
}

input[type="radio"]:checked + .custom-radio {
  background: var(--primary);
  box-shadow: inset 0 0 0 3px var(--background);
}

input[type="checkbox"]:checked + .custom-checkbox {
  background: var(--primary);
  border-color: var(--primary);
  color: white;
}

/* Error Styling */
.error .input-group input {
  border-color: #EF4444;
}

.error-message {
  color: #EF4444;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.submit-button {
  width: 100%;
  padding: 1rem;
  background: var(--primary);
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.submit-button:hover {
  transform: translateY(-2px);
  background: #248277;
}

/* Unified Input Styling */
.styled-input,
.styled-select,
.styled-textarea {
  width: auto;
  padding: 0.8rem 1.2rem;
  border: 2px solid #334155;
  border-radius: 0.5rem;
  background: #1E293B;
  color: var(--text);
  font-size: 1rem;
  transition: all 0.3s ease;
  appearance: none;
}

.styled-input:focus,
.styled-select:focus,
.styled-textarea:focus {
  border-color: var(--primary);
  box-shadow: 0 0 0 3px rgba(42, 157, 143, 0.2);
  outline: none;
}

/* Select Dropdown Styling */
.styled-select {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%232A9D8F' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}

/* Textarea Styling */
.styled-textarea {
  min-height: 100px;
  resize: vertical;
}

/* Checkbox and Radio Inputs */
input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  opacity: 0;
}

/* Profile Links Grid */
.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

/* Input Group Styling */
.input-group {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.input-group label {
  color: var(--text);
  font-weight: 500;
  font-size: 0.95rem;
}

/* Placeholder Styling */
::placeholder {
  color: #64748B;
  opacity: 1;
}

/* Error State Styling */
.error .styled-input,
.error .styled-select {
  border-color: #EF4444;
}

.error .styled-input:focus,
.error .styled-select:focus {
  box-shadow: 0 0 0 3px rgba(239, 68, 68, 0.2);
}

/* WhatsApp Notice Section */
.whatsapp-notice {
  padding: 1.5rem;
  margin: 2rem 0;
  background: rgba(234, 196, 106, 0.1);
  border-left: 4px solid var(--accent);
  border-radius: 8px;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.whatsapp-notice p {
  color: var(--text);
  margin: 0;
  font-size: 0.95rem;
  line-height: 1.6;
}

.whatsapp-link {
  color: var(--primary);
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}

.whatsapp-link:hover {
  color: var(--accent);
  text-decoration: underline;
}

.notice-icon {
  flex-shrink: 0;
  color: var(--accent);
  font-size: 1.5rem;
}
@media (max-width: 768px) {
  .whatsapp-notice {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
  }
  
  .notice-icon {
    align-self: center;
  }
}

/* Add these enhancements */
.coding-registration .form-section ul {
  padding-left: 1.5rem;
  margin: 1rem 0;
}

.coding-registration .form-section li {
  margin-bottom: 0.8rem;
  line-height: 1.5;
}

.coding-registration .highlight-item {
  background: rgba(0, 55, 255, 0.1);
  border: 1px solid var(--primary);
}

.coding-registration .form-section strong {
  color: var(--primary);
}

/* Responsive Design */
@media (max-width: 768px) {
  .coding-registration {
    margin: 1rem;
    padding: 1rem;
  }

  .form-header h1 {
    font-size: 2rem;
  }

  .form-grid {
    grid-template-columns: 1fr;
  }

  .checkbox-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .form-section {
    padding: 1rem;
  }

  .input-group input {
    padding: 0.7rem;
  }
}