@media screen and (max-width: 768px) {
  .coding-registration {
    width: 90%;
    padding: 1.5rem;
  }
  
  .checkbox-grid {
    grid-template-columns: 1fr;
  }
  
  .toggle-group {
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
  .coding-registration {
    padding: 1rem;
  }
  
  .form-section legend {
    font-size: 1rem;
  }
}